import { useEffect } from "react";

const HubSpotRegistrationForm = ({currentEvent = null, formID = '', eventID = '', afterSubmit, isEventsArchive = false}) => {
	
	const formInstanceID = ('hbspt-register-' + (currentEvent + formID + eventID + isEventsArchive));
	
	const getEventID = () => {
		if (currentEvent && currentEvent.id) {
			return currentEvent.id;
		} else if (eventID) {
			return eventID;
		} else {
			return 'unknown';
		}
	}
	
	useEffect(() => {
		const existing = document.getElementById('hbspt-forms');
		if (existing) {
			existing.parentElement.removeChild(existing);
		}
		const scriptElem = document.createElement('script');
		scriptElem.id = 'hbspt-forms';
		scriptElem.src = '//js.hsforms.net/forms/v2.js';
		scriptElem.addEventListener('load', () => {
			if (window.hasOwnProperty('hbspt') && window['hbspt'].hasOwnProperty('forms')) {
				window['hbspt'].forms.create({
					region: "na1",
					portalId: "5814764",
					formId: formID ? formID : "cde518f6-ddc5-44b1-9ad7-e772ab142fe9",
					target: "#" + formInstanceID,
					formInstanceId: formInstanceID,
					onFormSubmitted: function ($form) {
						localStorage.setItem('registration-done-' + getEventID(), new Date().toString());
						if (typeof afterSubmit === 'function') {
							afterSubmit();
						}
					}
				});
			}
		});
			
		document.head.appendChild(scriptElem);
	}, []);
	
	return <div className="hubspot-form" id={formInstanceID} />;
}

export default HubSpotRegistrationForm;