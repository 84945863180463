import { Icon, Button, Container } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react';
import ContentContainer from './ContentContainer.js'
import '../stylesheets/App.css'
import '../stylesheets/ContentHotspot.less'
import EventHotspot from "./hotspots/EventHotspot";
import transformHotspotName, { getContentEventName, isContentEventHotspotVisible, isRegisteredToContentEvent } from "../utils/helpers";
import RegistrationEventHotspot from './hotspots/RegistrationEventHotspot.js';
import EventArchiveHotspot from './hotspots/EventArchiveHotspot.js';

const ContentHotspot = ({ hotspotID, hotspotComponent, name, groupIndex, index, inViewpoint, content, hotspots, hotspotContents, types, setFloatContent, setFloatReturn, setUrlHotspot }) => {
    
    const [isOpen, setIsOpen] = useState(false)
    const [hotspotNameVisible, setHotspotNameVisible] = useState(false);
    const hotspotNameAlwaysVisible = (['product-information'].includes(name));
    const [screenCoords, setScreenCoords] = useState({
        x: hotspotComponent?.data.screenCoordX, 
        y: hotspotComponent?.data.screenCoordY,
        objectOffScreen: false
    })
    const isEventHotspot = types.indexOf("events") !== -1;
    const isEventArchiveHotspot = types.indexOf("events-archive") !== -1;
    const containerID = (typeof(content) === 'string' && content.includes("video")) ? "overlay-content-ui-video" : "overlay-content-ui";
    const [showRegisterForm, setShowRegisterForm] = useState(isEventHotspot 
        ? !isRegisteredToContentEvent(content) 
        : (isEventArchiveHotspot ? !isRegisteredToContentEvent({event_id: 'archive'}) : false)
    );
    
    const createScreenCoordUpdateRef = () => {
        if (groupIndex == null || index == null)
            return;
        window.ViewHotspotRefs[groupIndex][index] = () => {
            if (screenCoords.x === hotspotComponent?.data.screenCoordX
            && screenCoords.y === hotspotComponent?.data.screenCoordY) {
                return;
            }
            setScreenCoords({ 
                x: hotspotComponent?.data.screenCoordX || 0,
                y: hotspotComponent?.data.screenCoordY || 0,
                objectOffScreen: hotspotComponent?.data.objectOffScreen
            });
        }
    }
    createScreenCoordUpdateRef();

    useEffect( () => {
        createScreenCoordUpdateRef();

        setScreenCoords({ 
            x: hotspotComponent?.data.screenCoordX || 0,
            y: hotspotComponent?.data.screenCoordY || 0,
            objectOffScreen: hotspotComponent?.data.objectOffScreen
        });

        // Cleanup setScreenCoords reference when component unmounted
        return () => {
            if (groupIndex && index) {
                window.ViewHotspotRefs[groupIndex][index] = null;
            }
        }
    }, [hotspotComponent]);

    const handleOpenUrl = (url) => window.open(url);

    const openPopup = () => {
        const canvas = document.getElementsByClassName("a-canvas");
        if (canvas != null) {
            canvas[0].addEventListener('click', closePopup, true);
            canvas[0].style.opacity = 0.2;
        }
        hideShowHotspotButtons(true);
        setIsOpen(true);
        setUrlHotspot(transformHotspotName(name));
    }

    const closePopup = (event) => {
        event.preventDefault();
        window.sessionStorage.setItem('last-visited--' + hotspotID, (new Date()).toISOString()); // Set last visited time
        hideShowHotspotButtons(false);
        setIsOpen(false);
        const canvas = document.getElementsByClassName("a-canvas");
        if (canvas != null) {
            canvas[0].removeEventListener('click', closePopup, true);
            canvas[0].style.opacity = 1;
        }
        setUrlHotspot("");
    }

    const hideShowHotspotButtons = (hide) => {
        const coll = document.getElementsByClassName('hotspot-buttons-layer');
        for (let i = 0; i < coll.length; i++) {
            coll[i].hidden = hide;
        }
    }
    
    const generateViewpointStyle = () => {
        return { 
            left: (screenCoords.objectOffScreen) ? -100 : screenCoords.x, 
            right: 'auto', 
            top: (screenCoords.objectOffScreen) ? -100 : screenCoords.y,
            bottom: 'auto',
            zIndex: 1001,
            position: 'fixed',
            display: 'block'
        }
    }

    const generateHotspotButtons = (asInvisible = false) => {
      const isRoundButton = types.find((x) => x === "hotspot-round-button");
      const isRectangularButton = types.find((x) => x === "hotspot-rectangular-button");
      let isInvisible = types.find((x) => x === "invisible");
      if (!isInvisible && isEventHotspot) {
          isInvisible = !isContentEventHotspotVisible(content);
      }
      if (isInvisible && !asInvisible) {
          return null;
      }
      if (!isInvisible && asInvisible) {
          return null;
      }
      const nameVisible = (hotspotNameVisible || hotspotNameAlwaysVisible);
      const checkTypeExists = (itemToFind) => {
        return { [itemToFind]: types.includes(itemToFind)}
      }
      const hotspotNameMap = {
          "additionals-features": "fal fa-cogs",
          "images": "fal fa-images",
          "info": "fal fa-info-circle",
          "media": "fal fa-play",
          "meet-mydino": "fal fa-user-hard-hat",
          "welding": "fal fa-fire-alt",
      }
    //   console.log("array from hotspotNameMap: ", Object.entries(hotspotNameMap));
      const findIcon = () => {
        let foundIcon = "fal fa-info-circle";
        Object.entries(hotspotNameMap).forEach((arr) => {
          const [ key, val ] = arr;
        //   console.log("key & val: ", key, val, checkTypeExists(key));
          if (checkTypeExists(key)[key] === true) {
            //   console.log("why do we end up here?");
              foundIcon = val;
              return;
            }
          });
        return foundIcon;
      };

      const buttonLabel = isEventHotspot ? 'Join ' + getContentEventName(content) : name;
        
      return isRoundButton ? (
        <div className={"hotspot-button-group" + (isInvisible ? " invisible" : "")}>
          <Button 
          icon 
          color="blue"
          className="catch-pointer-events hotspot-button" 
          data-id={transformHotspotName(name)}
          onClick={(typeof(content) === 'string' && content.includes("https://")) ? () => handleOpenUrl(content) : openPopup}
          onMouseEnter={hotspotNameAlwaysVisible ? null : () => (setHotspotNameVisible(true))}
          onMouseLeave={hotspotNameAlwaysVisible ? null : () => (setHotspotNameVisible(false))}
          >
          <i className={"icon large " + findIcon()} />
          </Button>
          <Button 
          className="catch-pointer-events hotspot-label" 
          color="blue" size='large' 
          data-id={transformHotspotName(name)}
          onClick={(typeof(content) === 'string' && content.includes("https://")) ? () => handleOpenUrl(content) : openPopup}
          onMouseEnter={hotspotNameAlwaysVisible ? null : () => (setHotspotNameVisible(true))}
          onMouseLeave={hotspotNameAlwaysVisible ? null : () => (setHotspotNameVisible(false))}
          >
          { buttonLabel }
          </Button>
        </div>
      ) : (
        <div className="hotspot-button-group">
          <Button 
          icon 
          color="blue"
          data-id={transformHotspotName(name)}
          className="catch-pointer-events hotspot-rectangular-button" 
          onClick={(typeof(content) === 'string' && content.includes("https://")) ? () => handleOpenUrl(content) : openPopup}
          ><i className="icon fal fa-info-circle" /> {buttonLabel}
          <i className="icon fal fa-angle-double-right" />
          </Button>
        </div>
      );
    }

    useEffect(() => {
        if (isEventHotspot) {
            const contObj = document.getElementById(containerID);
            if (contObj) {
                if (showRegisterForm) {
                    if (contObj.classList.contains('events-overlay')) {
                        contObj.classList.remove('events-overlay');
                    }
                    if (!contObj.classList.contains('events-register-overlay')) {
                        contObj.classList.add('events-register-overlay');
                    }
                } else {
                    if (!contObj.classList.contains('events-overlay')) {
                        contObj.classList.add('events-overlay');
                    }
                    if (contObj.classList.contains('events-register-overlay')) {
                        contObj.classList.remove('events-register-overlay');
                    }
                }
            }
        }
      }, [showRegisterForm, isEventHotspot, containerID]);


    return (
        <>
            <div className="hotspot-buttons-layer" style={generateViewpointStyle()}>
                <div className="catch-pointer-events">
                    {generateHotspotButtons()}
                </div>
                <div className="no-pointer-events">
                    {generateHotspotButtons(true)}
                </div>
            </div>
            {
                (isOpen) ?
                <Container id={containerID} key="overlay-content-ui" className={"overlay-content scrolling-container catch-pointer-events" + (isEventHotspot ? (showRegisterForm ? ' events-register-overlay' : ' events-overlay') : '')}>
                    <Icon.Group className="hotspot-button-group close-spot-content catch-pointer-events" onClick={(e) => closePopup(e)}>
                        <i className="icon big fal fa-times" onClick={(e) => closePopup(e)} />
                        <Button
                            icon
                            color="blue"
                            className="catch-pointer-events hotspot-label"
                            onClick={(e) => closePopup(e)}
                        >Close</Button>
                    </Icon.Group>
                    {isEventHotspot && !showRegisterForm && <EventHotspot content={content} setFloatContent={setFloatContent} setFloatReturn={setFloatReturn} closePopup={closePopup} />}
                    {(isEventHotspot || isEventArchiveHotspot) && showRegisterForm && <RegistrationEventHotspot content={content} afterSubmit={() => setShowRegisterForm(false)} isEventsArchive={isEventArchiveHotspot} />}
                    {!isEventHotspot && isEventArchiveHotspot && !showRegisterForm && <EventArchiveHotspot hotspotContents={hotspotContents} hotspots={hotspots} inViewpoint={inViewpoint} content={content} closePopup={closePopup} key={groupIndex + '_cc_' + index} /*className="catch-pointer-events"*//>}
                    {!isEventHotspot && !isEventArchiveHotspot && <ContentContainer hotspotContents={hotspotContents} hotspots={hotspots} inViewpoint={inViewpoint} content={content} closePopup={closePopup} key={groupIndex + '_cc_' + index} /*className="catch-pointer-events"*//>}
                </Container>
                : null
            }
            {
                (isOpen) ?
                    <div className='content-hotspot-overlay'> </div>
                : null
            }
        </>
    )
};

ContentHotspot.propTypes = {
    hotspotComponent: PropTypes.object,
    name: PropTypes.string,
    groupIndex: PropTypes.number,
    index: PropTypes.number,
    inViewpoint: PropTypes.string,
}

export default ContentHotspot;