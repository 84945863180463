import { Entity, Scene } from 'aframe-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import imgStart from '../img/dino_showroom_start.jpg';
import imgRxt from '../img/dino_showroom_280rxt.jpg';
import imgRxtBasket from '../img/dino_showroom_280rxt_kori.jpg';
import imgPaviljonki from '../img/dino_showroom_paviljonki.jpg';
import imgVth from '../img/dino_showroom_230vth.jpg';
import imgVthBasket from '../img/dino_showroom_230vth_kori.jpg';
import imgXtb from '../img/dino_showroom_160xtb.jpg';
import imgXtbBasket from '../img/dino_showroom_160xtb_kori.jpg';
import imgXtc from '../img/dino_showroom_220xtc.jpg';
import imgXtcBasket from '../img/dino_showroom_220xtc_kori.jpg';
import imgBus from '../img/dino_showroom_bussi.jpg';
import imgFactory from '../img/tehdas_paanakyma_360.jpg';
import imgFinalAssembly from '../img/tehdas_loppukokoonpano_360.jpg';
import imgFactoryWelding from '../img/tehdas_hitsaus_360.jpg';
import imgFactoryMachining from '../img/tehdas_koneistus_360.jpg';
import imgFactoryBlowing from '../img/tehdas_puhallus_360.jpg';
import imgFactoryEarlyAssembly from '../img/tehdas_alkukokoonpano_360.jpg';
import imgFactoryInspection from '../img/tehdas_tarkastus_testaus_360.jpg';

const AScene = ({ hotspotList, sceneLoaded }) => {

    const [hotspots, setHotspots] = useState([])

    console.log("rendered Ascene")

    // Communicate upwards that scene was loaded
    useEffect( () => {
        sceneLoaded();
    }, [hotspots, sceneLoaded]);

    const createHotspots = () => {
            console.log('hotspotlist: ', hotspotList);
        const createdHotspots = hotspotList.map(group => {
            let allSpots = group.viewpoint_data.concat(group.hotspot_data || [])
            const groupEntities = allSpots.map( spot => {
                const hotspotData = `pointName: ${spot.name};
                    groupIndex: ${group.groupIndex}; 
                    pointIndex: ${spot.index};
                    linkToViewpoint: ${spot.linkToViewpoint || ""}; 
                    inViewpoint: ${spot.inViewpoint || ""}`;
                const hotspotPos = `${spot.position.x} ${spot.position.y} ${spot.position.z}`;
                return <Entity 
                    // primitive="a-image"
                    id={group.groupIndex + '_' + spot.name} 
                    name={spot.name} 
                    key={group.groupName + '_' + spot.name}
                    hotspot={hotspotData}
                    position={hotspotPos}
                />
            })
            let groupNameMod = group.groupName.replace(/\s+/g, '-').replace(',', '').toLowerCase();
            console.log('groupNameMod: ', groupNameMod);
            console.log('groupEntities: ', groupEntities);
            return (
                <Entity 
                    id={groupNameMod} 
                    key={groupNameMod} 
                    scale={(groupNameMod === window.activeViewpoint || "street") ? "1 1 1" : "0 0 0"}
                    hotspotgroup={`name: ${groupNameMod}`}>
                    {groupEntities}
                </Entity>
            )
        })
        setHotspots(createdHotspots);
        return createdHotspots;
    }

    return (
        <Scene background="color: #ECECEC" vr-mode-ui="enabled: false" loading-screen="enabled: false">

            {/* Only the lobby image is an asset, which is loaded before startup */}
            <a-assets timeout={60000}>
                <img id="img-street" src={imgStart} crossOrigin="anonymous" alt="background img"/>
                {/* <img id="img-hotspot" src="https://showroom-hubspot-test.s3-eu-west-1.amazonaws.com/aframe-tester/img-smaller/map-marker-alt-solid.svg" crossOrigin="anonymous" alt="hotspot icon"/> */}
            </a-assets>

            <a-sky id="skybox" src="#img-street"></a-sky>

            {/* Other showroom 360 images */}
            <img id="img-events" src={imgPaviljonki} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-rxt-series" src={imgRxt} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-280-rxt-basket" src={imgRxtBasket} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-vt-series" src={imgVth} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-230-vth-basket" src={imgVthBasket} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-trailers" src={imgXtb} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-160-xtb-basket" src={imgXtbBasket} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-xtc-ii-series" src={imgXtc} crossOrigin="anonymous" alt="background img"/>
            <img id="img-dino-220-xtc-basket" src={imgXtcBasket} crossOrigin="anonymous" alt="background img"/>
            <img id="img-factory-tour" src={imgBus} crossOrigin="anonymous" alt="background img"/>
            <img id="img-factory" src={imgFactory} crossOrigin="anonymous" alt="background img"/>
            <img id="img-final-assembly" src={imgFinalAssembly} crossOrigin="anonymous" alt="background img"/>
            <img id="img-welding" src={imgFactoryWelding} crossOrigin="anonymous" alt="background img"/>
            <img id="img-machining" src={imgFactoryMachining} crossOrigin="anonymous" alt="background img"/>
            <img id="img-paintwork-and-surface-treatment" src={imgFactoryBlowing} crossOrigin="anonymous" alt="background img"/>
            <img id="img-part-assembly" src={imgFactoryEarlyAssembly} crossOrigin="anonymous" alt="background img"/>
            <img id="img-final-inspection" src={imgFactoryInspection} crossOrigin="anonymous" alt="background img"/>

            {/* <a-box position="-1 0.5 -3" rotation="0 45 0" height="0.09" width="0.09" depth="0.09" color="#006298" shadow></a-box> */}

            <Entity rotation="0 90 0">
                <Entity id="cam" primitive="a-camera" position="0 1.6 0">
                    {/* Dummy sphere to help with fading out */}
                    <a-plane id="dummy-sphere" position="0 0 -0.3" color="#000000" material="shader: standard; opacity: 0" 
                        animation__fadeoutandin="startEvents: fadeoutandin; property: material.opacity; from: 0; to: 1; dur: 1000; dir: alternate; loop: 2;"
                        >
                    </a-plane>
                </Entity>
            </Entity>


            <Entity id="viewpoint-controller" viewpointcontroller>
                { (hotspots.length > 0) ? hotspots : createHotspots() } 
            </Entity>
        </Scene>
    );
}


AScene.propTypes = {
    hotspotList: PropTypes.array,
    sceneLoaded: PropTypes.func
}


export default AScene;
