import { useState, useEffect } from 'react';
import { getCurrentUnixTime } from '../utils/helpers';

const Countdown = ({startTime, onHideAction, doorsOpenBeforeStart = 0, className = '', isVisible = true}) => {
    const [countdownVisible, setCountdownVisible] = useState(false);
    const [t, setT] = useState(undefined);
	const [diff, setDiff] = useState({d: "0", h: "0", m: "0", s: "0"});
	const [isCancelled, setCancelled] = useState(false);

    const tick = () => {
		if (t) clearTimeout(t);
		if (!isCancelled) {
			const s = startTime - getCurrentUnixTime() - doorsOpenBeforeStart;
			if (s <= 0) {
				setCountdownVisible(false);
                if (typeof onHideAction === 'function') {
                    onHideAction();
                }
				return;
			}
			const days        = Math.floor(s/24/60/60);
			const hoursLeft   = Math.floor(s - (days*86400));
			const hours       = Math.floor(hoursLeft/3600);
			const minutesLeft = Math.floor((hoursLeft) - (hours*3600));
			const minutes     = Math.floor(minutesLeft/60);
			const seconds     = Math.floor(s % 60);

			setDiff({
				d: days.toString(),
				h: hours.toString().padStart(2, "0"),
				m: minutes.toString().padStart(2, "0"),
				s: seconds.toString().padStart(2, "0"),
			});

			const timer = setTimeout(tick, 1000);
			setT(timer);
		}
	};
	
	useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const currentTime = getCurrentUnixTime();
		if (!isNaN(startTime) && currentTime < (startTime - doorsOpenBeforeStart) && !(queryParams.has('showDebug'))) {
            setCountdownVisible(isVisible);
			tick();
		}
		
		return () => {
			setCancelled(true);
		}
	}, []);

    return (
        <div className={className}>
        { countdownVisible && <div>
                {parseInt(diff.d) > 0 ? <span>{diff.d} {diff.d !== "1" ? 'days' : 'day'},</span> : ''}
                <span>{diff.h} {diff.h !== "1" ? 'hours' : 'hour'},</span>
                <span>{diff.m} {diff.m !== "1" ? 'minutes' : 'minute'},</span>
                <span>{diff.s} {diff.s !== "1" ? 'seconds' : 'second'}</span>
            </div> }
        </div>
    );
}

export default Countdown;