import React, { useEffect, useState } from 'react'
import { Menu, Sidebar, Container, Icon, Button, Image, Segment } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import '../stylesheets/SubMenu.less'
import { MediaContextProvider, Media } from '../App.js'
import transformHotspotName from '../utils/helpers.js'

const SubMenu = ({ hotspots, isMobile, activeMenuItem }) => {
    const [activeItem, setActiveItem] = useState(activeMenuItem)
    const [sidebarOpened, setSidebarOpened] = useState(false)
    console.log("hotspots: ", hotspots);
    console.log("ActiveMenuItem: ", activeMenuItem);

    if (activeItem !== activeMenuItem) {
        // console.log("Setting active item to", activeMenuItem, "it used to be", activeItem);
        setActiveItem(activeMenuItem)
    }

    const handleItemClick = (e, { name }) => {
        setActiveItem(name);
        let newViewpoint = transformHotspotName(name);
        let currentViewpoint = transformHotspotName(activeItem);

        console.log(newViewpoint, currentViewpoint);

        window.moveToViewpoint(newViewpoint, currentViewpoint);
    }
    const subMenuItemArray = [
        'factory', 
        'final-assembly', 
        'welding', 
        'machining', 
        'paintwork-and-surface-treatment', 
        'part-assembly', 
        'final-assembly', 
        'final-inspection'
    ];
    
    const activeSubmenu = subMenuItemArray.includes(activeItem);

    const handleSidebarHide = () => setSidebarOpened(false)
    const handleToggle = () => setSidebarOpened(true) 
    const menuItems = hotspots.filter(g => g.visibleInFactoryTour === true);
    let activeItemIndex = -1;
    for (const m in menuItems) {
        const menuItem = menuItems[m];
        if (transformHotspotName(menuItem.groupName) === activeItem) {
            activeItemIndex = m;
            break;
        }
    }

    return (activeSubmenu ?
        <MediaContextProvider>
            <Media greaterThanOrEqual="computer">
                <Menu compact icon='labeled' className="submenu catch-pointer-events" vertical>
                    {
                        menuItems.map((group, index) => (
                            <Menu.Item
                            key={'submenuitem-' + index}
                            name={group.groupName}
                            active={activeItem === transformHotspotName(group.groupName)}
                            className={index <= activeItemIndex ? 'black' : ''}
                            onClick={handleItemClick}
                            >
                            <div className="numberCircle">{index + 1}</div>
                            {group.groupName}
                            </Menu.Item>
                        ))                       
                    }
                </Menu>
            </Media>
        </MediaContextProvider>
        : null
    )
}

SubMenu.propTypes = {
    hotspots: PropTypes.array,
    isMobile: PropTypes.bool,
    activeMenuItem: PropTypes.string
}


export default SubMenu;