import { getContentEventId, getContentEventName } from "../../utils/helpers";
import HubSpotRegistrationForm from "../hubspot-embeds/RegistrationForm";

const RegistrationEventHotspot = ({content, afterSubmit, isEventsArchive = false}) => {
	const eventID = isEventsArchive ? 'archive' : getContentEventId(content);

    return (
		<div 
			className={"hotspot-content-container-wrapper catch-pointer-events" + (isEventsArchive ? " events-archive" : "")} 
			style={{
				backgroundImage: isEventsArchive && content.hero_image ? ('url(' + content.hero_image.url + ')') : 'none',
				backgroundSize: 'cover'
			}}
		>
    		<div className={"hotspot-main-content-wrapper" + (isEventsArchive ? ' events-archive' : '')}>
				<div className="event-registration-form">
					{isEventsArchive && <div className="hotspot-full-width events-archive" dangerouslySetInnerHTML={{__html: content.content_full }}></div>}
					{!isEventsArchive && <>
						<h1>Welcome to {getContentEventName(content)}</h1>
						<h2>Log in to join the event</h2>
					</>}
					<div className="registration-form">
						{window && window.debug === true && <button onClick={() => {localStorage.setItem('registration-done-'+eventID, new Date().toString());afterSubmit();}}>Test Form Submit</button>}
						<HubSpotRegistrationForm 
							afterSubmit={afterSubmit} 
							eventID={eventID}
							formID={content.hubspot_form_id ? content.hubspot_form_id : ''}
							isEventsArchive={isEventsArchive}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RegistrationEventHotspot;