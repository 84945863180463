import { useState, useRef, useEffect } from "react";
import '../../stylesheets/ContentContainer.less';
import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css';
import { cleanVideoEmbedCode, removePElem } from "../../utils/helpers";
import { Button } from "semantic-ui-react";

const EventArchiveHotspot = ({inViewpoint, content, hotspotContents, hotspots, closePopup }) => {
	
	const getDefaultVideo = () => {
		if (content.gallery_video && content.gallery_video.length > 0) {
			return content.gallery_video[content.gallery_video.length - 1];
		}
		return {};
	};

	const [currentVideo, setCurrentVideo] = useState(getDefaultVideo());
	const [isMobile, setMobile] = useState(window && window.screen && window.screen.availWidth && window.screen.availWidth <= 760);
	const videoElem = useRef(null);
	const heroType = content.hero_video ? 'video' : (content.hero_image ? 'image' : 'none');
	const reversedVideos = content.gallery_video && content.gallery_video.length ? [...content.gallery_video].reverse() : [];

	const action = (video) => {
		setCurrentVideo(video);
		if (!isMobile && videoElem && videoElem.current) {
			videoElem.current.scrollIntoView({behavior: 'smooth'});
		}
	}

	useEffect(() => {
		const listener = () => {
			setMobile(window.screen.availWidth <= 760);
		}
		window.addEventListener('resize', listener);
		return () => {
			window.removeEventListener('resize', listener);
		}
	}, []);

	return <div className="hotspot-content-container-wrapper">
		{!!(content.hero_image || content.hero_video || content.tour_video) && <div className={"hero hero-" + heroType}>
			{!!(content.hero_video && !content.tour_video) &&
				<div dangerouslySetInnerHTML={{__html: cleanVideoEmbedCode(content.hero_video)}}/>}
			{!!(!content.hero_video && content.hero_image && content.hero_image.url) &&
				<img src={content.hero_image.url} alt={content.hero_image.title}/>}
		</div>}
		{!!(content.preheading || content.heading || content.subheading) && <div className="hotspot-headings">
			<div className="hotspot-headings-preheading">{content.preheading || ''}</div>
			{content.heading && <div className="hotspot-headings-main">{content.heading}</div>}
			<div className="hotspot-headings-subheading">{content.subheading || ''}</div>
		</div>}
		<div className={`hotspot-main-content-wrapper catch-pointer-events${!!content.content_all_centered ? ' text-center' : ''}`}>
			{content.content_full &&
				<div className="hotspot-full-width" dangerouslySetInnerHTML={{__html: content.content_full}}/>}

			{!!currentVideo.gallery_video_url && !isMobile && <div className="hotspot-full-width">
				<div ref={videoElem} className="hotspot-archive-main-video" dangerouslySetInnerHTML={{__html: cleanVideoEmbedCode(removePElem(currentVideo.gallery_video_url))}} />
			</div>}

			{ reversedVideos.map((video, index) => 
				<div className="hotspot-half-width archive-video" onClick={() => action(video)} key={'archived-video-' + index}>
					{isMobile 
						? <div className="hotspot-archive-video" dangerouslySetInnerHTML={{__html: cleanVideoEmbedCode(removePElem(video.gallery_video_url))}} />
						: <img className="hotspot-content-image" src={ video.gallery_video_placeholder.url } alt=""/>
					}
					<div className="hotspot-content-text">
						<h4>{ video.gallery_video_name }</h4>
						<p>{ video.gallery_video_description_text }</p>
					</div>
				</div>
			)}

			{!!content.content_close_button_text && <div className="hotspot-full-width">
				<Button
					className='close-content'
					onClick={(e) => closePopup(e)}
				>{ content.content_close_button_text }</Button>
			</div>}
		</div>
	</div>;
}

EventArchiveHotspot.propTypes = {
	inViewpoint: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default EventArchiveHotspot;
