import { useState } from "react";
import { getContentEventName, getContentEventStartTime, isContentEventOpen, removePElem } from "../../utils/helpers";
import Countdown from "../Countdown";

const EventHotspot = ({ content, closePopup, setFloatContent, setFloatReturn }) => {

	const doorsOpenBeforeStart = 900; // 15min
	const [isEventOpen, setEventOpen] = useState(isContentEventOpen(content, doorsOpenBeforeStart));
	const eventStartTime = getContentEventStartTime(content);
	const eventName = getContentEventName(content);

	return <div className="hotspot-content-container-wrapper catch-pointer-events">
		<div className="hotspot-main-content-wrapper hotspot-events-wrapper">
			{!isEventOpen ?

				<div className="event-info-preview">
					<h1>{eventName}</h1>
					<h2>Event starts</h2>
					<p>{new Date(eventStartTime * 1000).toString()}</p>
					<h2>Time before event opens</h2>
					<Countdown startTime={eventStartTime} doorsOpenBeforeStart={doorsOpenBeforeStart} onHideAction={() => setEventOpen(true)} className="event-countdown" />
				</div>

				: <>
					<div className="events-stream">
						<div className="stream"
							dangerouslySetInnerHTML={{ __html: removePElem(content.content_full) }} />
						{content.content_full_company && <div className="polllink"
							dangerouslySetInnerHTML={{ __html: content.content_full_company }} />}
					</div>
					<div className="events-chat">
						<div dangerouslySetInnerHTML={{ __html: removePElem(content.content_second) }} />
					</div>
					<div className="events-schedule">
						<div dangerouslySetInnerHTML={{ __html: content.content_first }} />
					</div>
				</>}
		</div>
	</div>
}

export default EventHotspot;