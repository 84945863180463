/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/require-default-props */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
/* eslint-disable no-alert, no-console */

import "../stylesheets/ViewHotspot.css";
import { Button, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable"; // The default

// eslint-disable-next-line max-len
const ViewHotspot = ({
	                     viewpointComponent,
	                     name,
	                     groupIndex,
	                     index,
	                     inViewpoint,
	                     linkTo,
	                     moveToViewpoint,
	                     content,
	                     types,
                     }) => {
	const hotspotNameAlwaysVisible = ![
		"Second viewpoint",
		"Panel repairing",
		"R3 Series",
	].includes(name);
	const [dragActive, setDragActive] = useState(false);
	const [hotspotNameVisible, setHotspotNameVisible] = useState(false);
	const [componentPosition, setComponentPosition] = useState({});
	const [screenCoords, setScreenCoords] = useState({
		x: viewpointComponent?.data.screenCoordX,
		y: viewpointComponent?.data.screenCoordY,
		objectOffScreen: false,
	});

	const buttonRef = useRef(null);

	const createScreenCoordUpdateRef = () => {
		window.ViewHotspotRefs[groupIndex][index] = () => {
			if (
				screenCoords.x === viewpointComponent?.data.screenCoordX &&
				screenCoords.y === viewpointComponent?.data.screenCoordY
			) {
				return;
			}
			setScreenCoords({
				x: viewpointComponent?.data.screenCoordX || 0,
				y: viewpointComponent?.data.screenCoordY || 0,
				objectOffScreen: viewpointComponent?.data.objectOffScreen,
			});
		};
	};
	createScreenCoordUpdateRef();

	useEffect(() => {
		createScreenCoordUpdateRef();

		setScreenCoords({
			x: viewpointComponent?.data.screenCoordX || 0,
			y: viewpointComponent?.data.screenCoordY || 0,
			objectOffScreen: viewpointComponent?.data.objectOffScreen,
		});

		// Cleanup setScreenCoords reference when component unmounted
		return () => {
			window.ViewHotspotRefs[groupIndex][index] = null;
		};
	}, [viewpointComponent]);

	const changeView = () => {
		moveToViewpoint(linkTo, inViewpoint);
	};

	const calculatePosition = () => {
		viewpointComponent.getUpdatedPosition()?.then((res) => {
			setComponentPosition(res);
		});
	};

	const showHotspotName = () => {
		setHotspotNameVisible(true);
	};

	const hideHotspotName = () => {
		setHotspotNameVisible(false);
	};

	const generateButtonStyle = () => {
		// const elementWidth = buttonRef?.current?.ref?.current?.offsetWidth;
		const elementWidth = 50;
		const moveLength = -elementWidth;
		const timing = 200;
		return {
			display: "block",
			backfaceVisibility: "hidden",
			transform: hotspotNameVisible
				? `translate(${moveLength}px)`
				: "translate(0px)",
			transition: `transform ${timing}ms`,
		};
	};

	const generateViewpointStyle = () => ({
		left: screenCoords.objectOffScreen ? -100 : screenCoords.x,
		right: "auto",
		top: screenCoords.objectOffScreen ? -100 : screenCoords.y,
		bottom: "auto",
		zIndex: 1001,
		position: "fixed",
		display: "block",
	});

	const generateProductViewpointButtons = () => {
		const nameVisible = hotspotNameVisible || hotspotNameAlwaysVisible;
		const isProductViewpoint = types.find((x) => x === "product-viewpoint");
		const isBackViewpoint = types.find((x) => x === "back-to-product");
		const is360View = types.find((x) => x === "360-viewpoint");

		const getIcon = () => {
			if (!types) {
				return "icon360";
			}
			if (types.indexOf("welding") !== -1 || types.indexOf("machining") !== -1) {
				return "fal fa-fire-alt";
			}
			if (types.indexOf("paintwork-and-surface-treatment") !== -1) {
				return "fal fa-tint";
			}
			if (types.indexOf("part-assembly") !== -1) {
				return "fal fa-chevron-right";
			}
			if (types.indexOf("final-assembly") !== -1) {
				return "fal fa-tools";
			}
			if (types.indexOf("final-inspection") !== -1) {
				return "fal fa-check-double";
			}
			return "icon360";
		}

		if (isProductViewpoint) {
			return (
				<div className="viewpoint-button-group">
					<Button
						ref={buttonRef}
						className="catch-pointer-events viewpoint-button"
						color="grey"
						size="large"
						onClick={changeView}
						onMouseEnter={hotspotNameAlwaysVisible ? null : showHotspotName}
						onMouseLeave={hotspotNameAlwaysVisible ? null : hideHotspotName}
						style={generateButtonStyle(buttonRef)}
					>
						Meet
					</Button>
					<Button
						ref={buttonRef}
						icon
						labelPosition="right"
						className="catch-pointer-events viewpoint-button"
						color="blue"
						size="large"
						onClick={changeView}
						onMouseEnter={hotspotNameAlwaysVisible ? null : showHotspotName}
						onMouseLeave={hotspotNameAlwaysVisible ? null : hideHotspotName}
						style={generateButtonStyle(buttonRef)}
					>
						{name}
						<i className="icon fal fa-angle-double-right large" />
					</Button>
				</div>
			);
		} else if (isBackViewpoint) {
			return (
				<Button
					icon
					className="catch-pointer-events viewpoint-rectangular-button"
					color="blue"
					size="large"
					onClick={changeView}
				>
					<i className="icon fal fa-street-view large" />
					{nameVisible ? name : null}
				</Button>
			);
		} else {
			return (
				<div className="hotspot-button-group">
					<Button
						icon
						color="blue"
						className="catch-pointer-events hotspot-button"
						onClick={changeView}
						onMouseEnter={hotspotNameAlwaysVisible ? null : () => setHotspotNameVisible(true)}
						onMouseLeave={hotspotNameAlwaysVisible ? null : () => setHotspotNameVisible(false)}
					>
						<i className={"icon large " + getIcon()} />
					</Button>
					<Button
						className="catch-pointer-events hotspot-label"
						color="blue"
						size="large"
						onClick={changeView}
						onMouseEnter={hotspotNameAlwaysVisible ? null : () => setHotspotNameVisible(true)}
						onMouseLeave={hotspotNameAlwaysVisible ? null : () => setHotspotNameVisible(false)}
					>
						{name}
					</Button>
				</div>);
		}
	};

	return window.debug ? (
		<Draggable>
			<div style={generateViewpointStyle()}>
				<div className="catch-pointer-events">
					<Button
						icon
						labelPosition="right"
						className="catch-pointer-events hotspot-button"
						color="blue"
						size="small"
						onClick={
							dragActive ? () => console.log("debug mode active") : changeView
						}
					>
						{name}
						<i className="icon fal fa-street-view large" />
					</Button>
				</div>
				<br />
				<Button
					onClick={() => setDragActive(!dragActive)}
					size="small"
					className="catch-pointer-events"
				>
					disable click
				</Button>
				<Button
					onClick={() => calculatePosition()}
					size="small"
					className="catch-pointer-events"
				>
					calcPos
				</Button>
				<Input
					className="catch-pointer-events"
					action={{
						color: "teal",
						labelPosition: "right",
					}}
					// eslint-disable-next-line max-len
					value={JSON.stringify({
						x: componentPosition?.x,
						y: componentPosition?.y,
						z: componentPosition?.z,
					})}
				/>
			</div>
		</Draggable>
	) : (
		<div className="hotspot-buttons-layer" style={generateViewpointStyle()}>
			<div className="catch-pointer-events">
				{generateProductViewpointButtons()}
			</div>
			<br />
		</div>
	);
};

ViewHotspot.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	viewpointComponent: PropTypes.object,
	name: PropTypes.string,
	groupIndex: PropTypes.number,
	index: PropTypes.number,
	inViewpoint: PropTypes.string,
	linkTo: PropTypes.string,
	moveToViewpoint: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	types: PropTypes.array,
};

export default ViewHotspot;
