const AFRAME = window.AFRAME;

AFRAME.registerComponent('viewpointcontroller', {
    schema: {
        currentScene: { type: "string", default: "" }
    },
    init: function() {
        
        // Make appropriate viewpoints visible for this viewpoint
        this.el.addEventListener('reloadviewpoints', (event) => {

            var currentGroup = document.getElementById(event.detail.currentViewpoints);
            // console.log(event.detail.currentViewpoints)
            currentGroup?.setAttribute("scale", "0 0 0");
            
            var newGroup = document.getElementById(event.detail.newViewpoints);
            // console.log(event.detail.newViewpoints)
            newGroup?.setAttribute("scale", "1 1 1");
        });
        
        // Fadeout effect
        this.el.addEventListener('startfadeout', (event) => {
            
            // Camera lookat point to zoom at and then start zoom
            // https://stackoverflow.com/questions/62806316/how-to-look-to-objects-using-lookat-with-a-frame-camera-component-and-look-con
            const camera = this.el.sceneEl.camera;
            const originalCameraRotation = {
                x: camera.el.components['look-controls'].pitchObject.rotation.x,
                y: camera.el.components['look-controls'].yawObject.rotation.y
            };
            let hotspotEls = this.el.sceneEl.querySelectorAll('[hotspot]');
            console.log('hotspotels: ', hotspotEls);
            // console.log('el.id:t ', el.id);
            console.log('event.detail: ', event.detail);            
            const formulateAsId = (str) => str.replace(/\s+/g, '-').replace(',', '').toLowerCase();
            
            // tried to fix this but actually this is not even used
            // let targetObj = [...hotspotEls].find(el => formulateAsId(el.id) === (el.components["hotspot"].groupIndex + '_' + event.detail));
            // console.log('targetobj: ', targetObj);

            // let targetPoint = this.getCameraTargetVector(camera.el, targetObj);
            // let targetPoint = targetObj?.getAttribute('position'); // if the viewpoint does not have any references, targetObj is undef.
            // console.log(targetPoint);

            const cameraPanStep = (timestamp) => {
                if (this.start === null) this.start = timestamp;
                var progress = timestamp - this.start;
                console.log(this)
                console.log(this.cameraEl)
                this.cameraEl.components["look-controls"].pitchObject.rotation.x = this.originalCameraRotation.x + this.x_rotation * progress / this.duration;
                this.cameraEl.components["look-controls"].yawObject.rotation.y = this.originalCameraRotation.y + this.y_rotation * progress / this.duration;
                if (progress < this.duration) {
                    requestAnimationFrame(this.step);
                }
            }

            let start = null;
            let duration = 1000;
            // let x_rotation = targetPoint.x - originalCameraRotation.x;
            // let y_rotation = targetPoint.y - originalCameraRotation.y;

            // todo fix
            // camera.el.components['look-controls'].pitchObject.rotation.x = originalCameraRotation.x + x_rotation * 0.05;
            // camera.el.components['look-controls'].yawObject.rotation.y = originalCameraRotation.y + y_rotation * 0.05;

            // const boundCameraPanStep = cameraPanStep.bind(this);
            // requestAnimationFrame(boundCameraPanStep);

            
            camera.el.emit('fadeoutzoom')
            
            // Start fadeout
            let dummySphere = document.getElementById("dummy-sphere");
            dummySphere.emit('fadeoutandin')

            const waitSomeTime = async () => {
                const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
                await wait(1000);
            }
            waitSomeTime().then( () => {
                this.el.emit('changeskybox', event.detail);
                camera.el.components['look-controls'].pitchObject.rotation.x = originalCameraRotation.x;
                camera.el.components['look-controls'].yawObject.rotation.y = originalCameraRotation.y;
                // console.log("camera stuff");
            })
        });

        // Change 360 picture according to viewpoint change
        this.el.addEventListener('changeskybox', (event) => {
            
            var sky = document.getElementById("skybox");
            sky.setAttribute("src", `#img-${event.detail}`);
        });

    },
    getCameraTargetVector: function(camera, targetObj) {
        let targetWorldPos = new window.THREE.Vector3();
        // console.log(targetObj.getAttribute('position'))
        targetObj.object3D.getWorldPosition(targetWorldPos);
        let cameraPos = new window.THREE.Vector3();
        camera.object3D.getWorldPosition(cameraPos);

        // console.log(targetWorldPos);

        let vector = new window.THREE.Vector3(targetWorldPos.x, targetWorldPos.y, targetWorldPos.z);
        vector.subVectors(cameraPos, vector).add(cameraPos);
        return vector;
    }
});