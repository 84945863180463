import React, { useState } from 'react'
import { Menu, Sidebar, Icon, Button, Image, Segment } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import '../stylesheets/MainMenu.less'
import { MediaContextProvider, Media } from '../App.js'
import transformHotspotName from '../utils/helpers.js'
import dinoLogo from '../../src/img/logo.png'

const MainMenu = ({ hotspots, isMobile, activeMenuItem, hasCurrentEvent }) => {
    const [activeItem, setActiveItem] = useState(activeMenuItem);
    const [sidebarOpened, setSidebarOpened] = useState(false);

    if (activeItem !== activeMenuItem)
        setActiveItem(activeMenuItem);
    
    const contactClick = () => {
        const contactHotspot = document.querySelector("button[data-id='contact']");
        if (contactHotspot) {
            contactHotspot.click();
        }
    }

    const myDINOClick = () => {
        const myDINOHotspot = document.querySelector("button[data-id='meet-mydino']");
        if (myDINOHotspot) {
            myDINOHotspot.click();
        }
    }

    const handleItemClick = (e, { name }) => {
        let newViewpoint = transformHotspotName(name);
        let currentViewpoint = transformHotspotName(activeItem);
        
        if (name.toLowerCase() === "contact") {
            e.preventDefault();
            if (currentViewpoint !== "street") {
                window.moveToViewpoint("street", currentViewpoint);
                setTimeout(contactClick, 300);
            } else {
                contactClick();
            }
            
            return;
        }

        if (name.toLowerCase() === "mydino") {
            e.preventDefault();
            if (currentViewpoint !== "street") {
                window.moveToViewpoint("street", currentViewpoint);
                setTimeout(myDINOClick, 300);
            } else {
                myDINOClick();
            }

            return;
        }

        // console.log(newViewpoint, currentViewpoint);

        window.moveToViewpoint(newViewpoint, currentViewpoint);
    }
    const handleSidebarHide = () => setSidebarOpened(false)
    const handleToggle = () => setSidebarOpened(true)
    
    return (
        <MediaContextProvider>
            <Media greaterThanOrEqual="computer" className="catch-pointer-events">
                <Menu secondary className="catch-pointer-events" id="main-menu">
                    <Menu.Item position='left' key='Dino logo' onClick={() => window.location.href = '/'}> 
                        <Image className='custom-size-logo' size='small' src={dinoLogo}></Image>
                    </Menu.Item>
                    {
                        hotspots.filter(g => g.visibleInMainMenu === true).map(group => (
                            <Menu.Item
                                // position='left'
                                key={group.groupName}
                                name={group.groupName}
                                className={"custom-menu-item " + transformHotspotName(group.groupName)}
                                active={activeItem === transformHotspotName(group.groupName)}
                                onClick={(event) => {
                                    handleItemClick(event, {name: group.groupName});
                                }}
                            > {group.groupName}
                                {!!(transformHotspotName(group.groupName) === "events" && hasCurrentEvent) && <span className="events-live-badge">live</span>}
                            </Menu.Item>
                        ))
                    }
                    <Menu.Item
                        className="static-menu-item"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open("https://www.dinolift.com/extranet/");
                        }}
                    >
                        Extranet&nbsp;<i className="fal icon fa-icon fa-sign-in" />
                    </Menu.Item>
                </Menu>
            </Media>
            <Media as={Sidebar.Pushable} lessThan='computer' className="catch-pointer-events">
                <Sidebar
                    as={Menu}
                    direction='top'
                    animation='overlay'
                    onHide={handleSidebarHide}
                    vertical
                    pointing
                    secondary
                    visible={sidebarOpened}
                    className='catch-pointer-events'
                    id="main-menu"
                    >
                        <Menu.Item
                            key='Close menu'
                            onClick={handleSidebarHide}
                        >
                            <Button.Content>
                                {/* <Image size='tiny' src={rauteLogo}></Image> */}
                            </Button.Content>
                            <Button.Content style={{ "textAlign": "right", "marginTop": "-12px" }}>
                                <Icon name='close'/>
                            </Button.Content>
                        </Menu.Item>
                        {
                            hotspots.filter(g => g.visibleInMainMenu === true).map(group => (
                                <Menu.Item
                                    key={group.groupName}
                                    name={group.groupName}
                                    active={activeItem === transformHotspotName(group.groupName)}
                                    onClick={(event) => {
                                        handleItemClick(event, {name: group.groupName});
                                        handleSidebarHide(event);
                                    }}
                                    > {group.groupName}
                                </Menu.Item>
                            ))
                        }
                        <br/>
                </Sidebar>
                <Sidebar.Pusher dimmed={sidebarOpened} className='mobile-menu-pusher'>
                    <Menu 
                        pointing 
                        secondary 
                        size='small'
                        className='catch-pointer-events'
                    >
                        <Menu.Menu position='left'>
                            <Menu.Item key='Raute logo'>
                                {/* <Image className='custom-size-logo' size='small' src={rauteLogo}></Image> */}
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Item position='right' onClick={handleToggle}>
                            <Icon size='big' name='sidebar' />
                        </Menu.Item>
                    </Menu>
                </Sidebar.Pusher>
            </Media>
        </MediaContextProvider>
    )
}

MainMenu.propTypes = {
    hotspots: PropTypes.array,
    isMobile: PropTypes.bool,
    toggleOpen: PropTypes.func,
    activeMenuItem: PropTypes.string
}


export default MainMenu;