import { useEffect } from "react";

const HubSpotContactForm = () => {
	
	const formID = ('hbspt-contact-' + new Date().getTime() + new Date().getMilliseconds()).replace(/\s+/g, '');
	
	useEffect(() => {
		const existing = document.getElementById('hbspt-forms');
		if (existing) {
			existing.parentElement.removeChild(existing);
		}
		const scriptElem = document.createElement('script');
		scriptElem.id = 'hbspt-forms';
		scriptElem.src = '//js.hsforms.net/forms/v2.js';
		scriptElem.addEventListener('load', () => {
			window['hbspt'].forms.create({
				region: "na1",
				portalId: "5814764",
				formId: "5ede7b0b-2ce4-411b-94e1-8c2ab348ec47",
				target: "#" + formID,
				formInstanceId: formID
			});
		});
		document.head.appendChild(scriptElem);
	}, []);
	
	return <div className="hubspot-form" id={formID} />;
}

export default HubSpotContactForm;