import '../stylesheets/Overlay2D.less';
import { Container } from 'semantic-ui-react'
import MainMenu from './MainMenu.js'
import SubMenu from './SubMenu.js'
import PropTypes from 'prop-types'
import HotspotContainer from './HotspotContainer';
import React, { useEffect, useState } from 'react'
import { moveToViewpoint } from "../utils/helpers";

const OverlayUI = ({ hotspots, isMobile, isSceneLoaded, setFloatContent, setFloatReturn, hasCurrentEvent }) => {
    const [activeMenuItem, setActiveMenuItem] = useState("street");
    const [scene, hotspot] = window && window.location.hash ? window.location.hash.toString().replace('#', '').substring(1).split('/') : ["", ""];
    const [urlScene, setUrlScene] = useState(scene);
    const [urlHotspot, setUrlHotspot] = useState(hotspot);
    const [currentViewpoint, setCurrentViewpoint] = useState("street");

    const setMainMenuActiveItem = (toActivate) => {
        setActiveMenuItem(toActivate);
        setUrlScene(toActivate);
        setUrlHotspot("");
    }

    useEffect(() => {
        window.noHashChange = true;
        if (urlScene) {
            window.location.hash = '/' + encodeURIComponent(urlScene) + (urlHotspot ? '/' + encodeURIComponent(urlHotspot) : '');
        } else {
            window.location.hash = '';
        }
        setTimeout(() => {
            delete window.noHashChange;
        }, 100);
    }, [urlScene, urlHotspot]);
    
    const hashChangeHandler = () => {
        if (window.location.hash && window['noHashChange'] !== true) {
            const [changedScene, changedHotspot] = window.location.hash.toString().replace('#', '').substring(1).split('/');
            if (scene) {
                if (decodeURIComponent(changedScene) !== currentViewpoint) {
                    moveToViewpoint(
                        decodeURIComponent(changedScene), 
                        currentViewpoint, 
                        setMainMenuActiveItem, 
                        setUrlScene, 
                        setCurrentViewpoint
                    );
                }
                if (changedHotspot) {
                    const hotspotElement = document.querySelector('button[data-id="' + decodeURIComponent(changedHotspot) + '"]');
                    if (hotspotElement) {
                        hotspotElement.click();
                    }
                }
            }
        }
    };
    
    useEffect(() => {
        window.addEventListener("hashchange", hashChangeHandler, false);
        
        return () => {
            window.removeEventListener("hashchange", hashChangeHandler);
        }
    }, []);
    
    useEffect(() => {
        if (isSceneLoaded) {
            // update hash with itself now that scene should be loaded
            setTimeout(() => {
                delete window.noHashChange;
                window.dispatchEvent(new HashChangeEvent("hashchange"));
            }, 1000);
        }
    }, [isSceneLoaded]);

    return (
        <>
            <Container id="debug-container">
                {
                    (window.debug) ? <div style={{ bottom: 0, left: 0 }}>Debug</div> : <></>
                }
            </Container>
            <HotspotContainer 
                hotspots={hotspots} 
                isMobile={isMobile} 
                isSceneLoaded={isSceneLoaded} 
                setMainMenuActiveItem={setMainMenuActiveItem} 
                setFloatContent={setFloatContent}
                setFloatReturn={setFloatReturn}
                setUrlScene={setUrlScene}
                setUrlHotspot={setUrlHotspot}
                currentViewpoint={currentViewpoint}
                setCurrentViewpoint={setCurrentViewpoint}
            />
            <Container className="overlay-ui main-menu-container">
                <MainMenu hotspots={hotspots} isMobile={isMobile} activeMenuItem={activeMenuItem} hasCurrentEvent={hasCurrentEvent} />
            </Container>
            <Container className="overlay-ui sub-menu-container">
                <SubMenu hotspots={hotspots} isMobile={isMobile} activeMenuItem={activeMenuItem}/>
            </Container>
        </>
    )
}

OverlayUI.propTypes = {
    hotspots: PropTypes.array,
    isMobile: PropTypes.bool,
    isSceneLoaded: PropTypes.bool
}

export default OverlayUI;