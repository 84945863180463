import { cleanVideoEmbedCode } from "../../utils/helpers";

const GalleryItem = ({item, type}) => {
	
	const getText = () => {
		if (type !== 'video' && !!item.caption) {
			return item.caption;
		}
		return (type === "video" ? item.gallery_video_name : item.title).replace(/_/g, ' ');
	}

	return <div className="gallery-reel-item">
		{(type === "video" && item.gallery_video_placeholder) && <img src={item.gallery_video_placeholder.url} alt={item.gallery_video_placeholder.alt} />}
		{(type === "video" && !item.gallery_video_placeholder) && <div dangerouslySetInnerHTML={{ __html: cleanVideoEmbedCode(item.gallery_video_url) }} />}
		{type === "image" && <img src={item.sizes.thumbnail} alt={item.alt} />}
		<p>{ getText() }</p>
	</div>;
}

export default GalleryItem;