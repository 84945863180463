import { useEffect, useState } from 'react';
import 'semantic-ui-less/semantic.less';
import './stylesheets/App.css';
import './stylesheets/HubSpotForms.less';
import AScene from './react-components/AScene.js';
import OverlayUI from './react-components/OverlayUI.js';
import { createMedia } from '@artsy/fresnel';
import hotspotList from './data/hotspot_list.json';
import Draggable from "react-draggable";
import { Icon } from "semantic-ui-react";
import RegistrationForm from "./react-components/hubspot-embeds/RegistrationForm";
import { eventsClick, getCurrentUnixTime } from "./utils/helpers";
import Countdown from './react-components/Countdown';

export const { MediaContextProvider, Media } = createMedia({
	breakpoints: {
		mobile: 0,
		tablet: 800,
		computer: 1371
	},
})

window.componentsInitialized = 0;
window.debug = false;

const App = () => {
	const [hotspots, setHotspots] = useState(hotspotList);
	const [isSceneLoaded, setIsSceneLoaded] = useState(false);
	const isMobile = false;
	const [countdownVisible, setCountdownVisible] = useState(false);
	const targetTime = 1644933600;
	const doorsOpenBeforeTarget = 14000;
	const eventTimes = [
		{id: "launch", start:new Date(1644933600 * 1000), end: new Date(1644939000 * 1000)},
	];
	const [floatContent, setFloatContent] = useState("");
	const [floatReturn, setFloatReturn] = useState("");
	
	useEffect(() => {
		setHotspots(hotspotList);
	}, [hotspotList]);
	
	const getActiveEvent = (forRegister = false) => {
		const currentTime = new Date();
		let active = false;
		const queryParams = new URLSearchParams(window.location.search);
		if (queryParams.has('debugEventForm')) {
			active = eventTimes[0];
		} else {
			for (const eventObject of eventTimes) {
				if (eventObject.start <= currentTime && eventObject.end > currentTime) {
					active = eventObject;
					break;
				}
			}
		}
		if (forRegister && active) {
			// check if user has already registered to this event
			if (localStorage.getItem('registration-done-' + active.id)) {
				return false;
			}
		}
		return active;
	}
	
	useEffect(() => {
		if (getCurrentUnixTime() < (targetTime - doorsOpenBeforeTarget)) {
			setCountdownVisible(true);
		}
	}, []);

	const onSceneLoaded = () => {
		// console.log("onSceneLoaded");
		let cameraEl = document.getElementById("cam");
		cameraEl.setAttribute('look-controls', 'reverseMouseDrag: true; reverseTouchDrag: true;');
		cameraEl.setAttribute('wasd-controls', 'enabled: false');
		cameraEl.setAttribute('animation__fadeoutzoom', "startEvents: fadeoutzoom; property: camera.zoom; from: 1; to: 2; dur: 1000; easing: easeInOutQuad; loop: 2; dir: alternate;");
		setIsSceneLoaded(true);
	}
	
	const requireRegisterForm = !!(getActiveEvent(true));

	return (
		<div className="App">
			{countdownVisible && <div className="Countdown">
				<h1 className="Countdown-Text">Welcome to DINO Talkx</h1>
				<h2 className="Countdown-Text">Countdown to launch</h2>
				<Countdown startTime={targetTime} doorsOpenBeforeStart={doorsOpenBeforeTarget} onHideAction={() => setCountdownVisible(false)} className="Countdown-Text" />
			</div>}
			{requireRegisterForm && <div className="Countdown">
				<h1 className="Countdown-Text">Welcome to DINO Talkx</h1>
				<h2 className="Countdown-Text">Log in to join the event</h2>
				<div className="registration-form">
					<RegistrationForm currentEvent={getActiveEvent()} />
				</div>
			</div>}
			{!requireRegisterForm && <>
				<AScene hotspotList={hotspots} sceneLoaded={onSceneLoaded}/>
				<OverlayUI 
					hotspots={hotspots} 
					isMobile={isMobile} 
					isSceneLoaded={isSceneLoaded} 
					setFloatContent={setFloatContent} 
					setFloatReturn={setFloatReturn}
					hasCurrentEvent={!!getActiveEvent()}
				/>
			</>}
			{floatContent && <Draggable handle=".drag-handle">
				<div className="global-float-content">
					<Icon.Group className="global-float-content-controls catch-pointer-events">
						<i className="drag-handle icon big fa-icon fal fa-arrows" />
						{floatReturn && <i 
							className="icon big fal fa-external-link rotate270" 
							onClick={() => {
								setFloatContent("");
								if (floatReturn === "events") {
									eventsClick();
								}
								setFloatReturn("");
							}} 
						/>}
						<i className="icon big fal fa-times" onClick={() => setFloatContent("")} />
					</Icon.Group>
					<div className="global-float-content-container" dangerouslySetInnerHTML={{__html: floatContent}} />
				</div>
			</Draggable>}
			<a className="blue-link feedback-link" href="https://survey.hsforms.com/19NEz7m_7Q3-_YIWes5PFpg3gmp8" target="_blank" rel="noopener">
				<span>Give us</span> feedback <i className="icon fa-icon large fal fa-smile" />
			</a>
		</div>
	);
}

export default App;
