import '../stylesheets/ContentContainer.less';
import PropTypes from 'prop-types'
import { Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from "./hotspots/Gallery";
import transformHotspotName, { cleanVideoEmbedCode } from "../utils/helpers";
import HubSpotContactForm from "./hubspot-embeds/ContactForm";
import { Button } from "semantic-ui-react";

const ContentContainer = ({inViewpoint, content, hotspotContents, hotspots, closePopup }) => {
	
	const heroType = content.hero_video ? 'video' : (content.hero_image ? 'image' : 'none');
	
	const resolveReadMore = (link) => {
		let targetID = null;
		if (typeof(link) === 'object') {
			if (!(link.hasOwnProperty('ID'))) {
				return '/'; // it would be a horribly broken link at this point
			}
			targetID = link.ID;
		}
		if (link.toString().indexOf('wp-admin') !== -1) {
			const url = new URL(link);
			const urlParams = new URLSearchParams(url.search);
			targetID = urlParams.get('post');
		} else if (link.toString().match(/^\d+$/)) {
			targetID = link.toString();
		}
		if (targetID) {
			// search for matching hotspot
			let targetSlug = null;
			for (const c in hotspotContents) {
				if (hotspotContents[c].id.toString() === targetID) {
					targetSlug = hotspotContents[c].slug;
					break;
				}
			}
			if (!targetSlug) {
				return '/';
			}
			let foundScene = null;
			let foundHotspot = null;
			for (const g in hotspots) {
				const group = hotspots[g];
				if (!(group.hasOwnProperty('groupName'))) continue;
				
				for (const h in group.hotspot_data) {
					if (group.hotspot_data[h].hasOwnProperty('slug') && group.hotspot_data[h].slug === targetSlug) {
						foundScene = transformHotspotName(group.groupName);
						foundHotspot = transformHotspotName(group.hotspot_data[h].name);
						break;
					}
				}
				if (foundHotspot) {
					break;
				}
			}
			if (foundHotspot) {
				return '#/' + encodeURIComponent(foundScene) + '/' + encodeURIComponent(foundHotspot);
			}
		}
		return link;
	};
	
	const meetingScript = () => {
		const script = document.createElement('script');
		script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
		script.onload = () => {
			document.body.removeChild(script);
		}
		document.body.appendChild(script);
		
		return <></>;
	}
	
	const replaceAContent = (html, replaceText) => {
		if (!html) return html;
		
		const tmpElement = document.createElement('div');
		tmpElement.innerHTML = html;
		if (tmpElement.firstElementChild && tmpElement.firstElementChild.tagName.toLowerCase() === 'a') {
			tmpElement.firstElementChild.innerHTML = replaceText + ' <i class="fa-icon icon large fal fa-play" />';
			tmpElement.firstElementChild.setAttribute('_target', 'blank');
			tmpElement.firstElementChild.setAttribute('rel', 'noopener');
			tmpElement.firstElementChild.classList.add('blue-link');
			return tmpElement.innerHTML;
		}
		return html;
	}
	
	const scrollToCalendar = () => {
		const calendarElement = document.getElementsByClassName("hotspot-calendar")[0];
		if (calendarElement) {
			document.getElementById("overlay-content-ui").scrollTo({
				behavior: 'smooth',
				left: calendarElement.offsetLeft,
				top: calendarElement.offsetTop + calendarElement.clientHeight - 150
			});
		}
	}

	return <div className="hotspot-content-container-wrapper">
		{!!(content.hero_image || content.hero_video) && <div className={"hero hero-" + heroType}>
			{!!(content.hero_video) &&
				<div dangerouslySetInnerHTML={{__html: cleanVideoEmbedCode(content.hero_video)}}/>}
			{!!(!content.hero_video && content.hero_image && content.hero_image.url) &&
				<img src={content.hero_image.url} alt={content.hero_image.title}/>}
		</div>}
		{!!(content.preheading || content.heading || content.subheading) && <div className="hotspot-headings">
			<div className="hotspot-headings-preheading">{content.preheading || ''}</div>
			{content.heading && <div className="hotspot-headings-main">{content.heading}</div>}
			<div className="hotspot-headings-subheading">{content.subheading || ''}</div>
		</div>}
		<div className={`hotspot-main-content-wrapper catch-pointer-events${!!content.content_all_centered ? ' text-center' : ''}`}>
			{content.calendar && <Button className="hotspot-calendar-button" color="blue" onClick={scrollToCalendar}>
				Book a meeting <i className="button icon fa-icon fal big fa-calendar-alt" />
			</Button>}
			{content.content_full &&
				<div className="hotspot-full-width" dangerouslySetInnerHTML={{__html: content.content_full}}/>}

			{content.tour_video && 
				<div className="hotspot-full-width tour-video" dangerouslySetInnerHTML={{__html: cleanVideoEmbedCode(content.tour_video)}}/>}

			{content.tour_content_full &&
				<div className="hotspot-full-width" dangerouslySetInnerHTML={{__html: content.tour_content_full}}/>}

			{!!(content.content_first || content.content_first_image) && <div className="hotspot-half-width">
				<img className={"hotspot-content-image" + (content.content_first === "" ? " no-content" : "")} src={content.content_first_image.url}
				     alt={content.content_first_image.alt}/>
				<div className="hotspot-content-text" dangerouslySetInnerHTML={{__html: content.content_first}}/>
			</div>}

			{!!(content.content_second || content.content_second_image) && <div className="hotspot-half-width">
				<img className={"hotspot-content-image" + (content.content_second === "" ? " no-content" : "")} src={content.content_second_image.url}
				     alt={content.content_second_image.alt}/>
				<div className="hotspot-content-text" dangerouslySetInnerHTML={{__html: content.content_second}}/>
			</div>}

			{content.content_features &&
				<div className="hotspot-full-width" dangerouslySetInnerHTML={{__html: content.content_features}}/>}
			{!!(content.features_links && content.features_links.length) &&
				<div className="hotspot-full-width no-top-gap flex">
					{content.features_links.map((featureLink, index) => {
						return <a
							className="blue-link download"
							key={'feature-link-' + index}
							target="_blank"
							rel="noopener"
							href={typeof (featureLink.features_links_file) === 'string' ? featureLink.features_links_file : featureLink.features_links_file.url}
						>
							{featureLink.features_links_text}
							&nbsp;<i className="icon fal fa-file-pdf"/>
						</a>
					})}
				</div>}

			{content.content_options &&
				<div className="hotspot-full-width" dangerouslySetInnerHTML={{__html: content.content_options}}/>}
			{!!(content.options_links && content.options_links.length) &&
				<div className="hotspot-full-width no-top-gap flex">
					{content.options_links.map((optionLink, index) => {
						return <a
							className="blue-link download"
							key={'feature-link-' + index}
							target="_blank"
							rel="noopener"
							href={typeof (optionLink.options_links_file) === 'string' ? optionLink.options_links_file : optionLink.options_links_file.url}
						>
							{optionLink.options_links_text}
							&nbsp;<i className="icon fal fa-file-pdf"/>
						</a>
					})}
				</div>}

			{!!(content.alldownloads_links && content.alldownloads_links.length) &&
				<div className="hotspot-full-width">
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>All downloads</Accordion.Header>
							<Accordion.Body className="downloads-list">
								{content.alldownloads_links.map((downloadLink, index) => {
									return <a
										className="blue-link download"
										key={'feature-link-' + index}
										target="_blank"
										rel="noopener"
										href={typeof (downloadLink.alldownloads_links_file) === 'string' ? downloadLink.alldownloads_links_file : downloadLink.alldownloads_links_file.url}
									>
										{downloadLink.alldownloads_links_text}
										&nbsp;<i className="icon fal fa-file-pdf"/>
									</a>
								})}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>}

			{!!(content.gallery_video && content.gallery_video.length) && <Gallery 
				gallery_hide_heading={content.gallery_hide_heading}
				items={content.gallery_video} 
				type={"video"} 
			/>}

			{content.content_full_company &&
				<div className="hotspot-full-width" dangerouslySetInnerHTML={{__html: content.content_full_company}}/>}

			{!!(content.gallery && content.gallery.length) && <Gallery
				gallery_hide_heading={content.gallery_hide_heading}
				items={content.gallery} 
				type={"image"} 
			/>}

			{!!(content.tour_gallery && content.tour_gallery.length) && <Gallery
				gallery_hide_heading={content.gallery_hide_heading}	
				items={content.tour_gallery} 
				type={"image"} 
			/>}

			{content.content_didyouknow && <div className="hotspot-full-width">
				<h2>'Did you know'</h2>
				<strong><span dangerouslySetInnerHTML={{__html: content.content_didyouknow}} /></strong>
			</div>}

			{!!(!!(content.readmore_link || content.readmore_hotspot) && content.readmore_text) && <div className="hotspot-full-width">
				{content.readmore_link && <a className="blue-link" href={resolveReadMore(content.readmore_link)}>
					{content.readmore_text}
				</a>}
				{!!(!content.readmore_link && content.readmore_hotspot) && <a className="blue-link" href={resolveReadMore(content.readmore_hotspot)}>
					{content.readmore_text}
				</a>}
			</div>}
			
			{content.contact_form && <div className="hotspot-contact hotspot-full-width">
				<h2>{content.contact_form}</h2>
				<HubSpotContactForm />
			</div>}

			{!!(content.person && content.person.person_name) && <div className="hotspot-person hotspot-full-width">
				{content.person.person_image && <img 
					alt={content.person.person_image.alt}
					className="hotspot-person-image"
					src={content.person.person_image.sizes.thumbnail} 
				/>}
				<div className="hotspot-person-info">
					<div className="hotspot-person-info--name">{content.person.person_name}</div>
					<div className="hotspot-person-info--title">{content.person.person_title}</div>
					<div className="hotspot-person-info--phone">
						<a href={"tel:" + content.person.person_phone}>{content.person.person_phone}</a>
					</div>
					<div className="hotspot-person-info--email">
						<a href={"mailto:" + content.person.person_email}>{content.person.person_email}</a>
					</div>
				</div>
			</div>}

			{content.calendar && <div className="hotspot-calendar hotspot-full-width">
				<h2>{content.calendar}</h2>
				<div className="meetings-iframe-container"
				     data-src="https://meetings.hubspot.com/mina-lim/round-robin?embed=true" />
				{meetingScript()}
			</div>}

			{!!content.content_close_button_text && <div className="hotspot-full-width">
				<Button
					className='close-content'
					onClick={(e) => closePopup(e)}
				>{ content.content_close_button_text }</Button>
			</div>}
		</div>
	</div>;
}

ContentContainer.propTypes = {
	inViewpoint: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default ContentContainer;
