// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import GalleryItem from "./GalleryItem";
import { useEffect, useState } from "react";
import { Navigation, Thumbs } from "swiper"
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { Swiper } from 'swiper/react/swiper';
import { SwiperSlide } from 'swiper/react/swiper-slide';
import GalleryButton from "./GalleryButton";
import { cleanVideoEmbedCode } from "../../utils/helpers";

const Gallery = ({items, type, gallery_hide_heading = false}) => {

	const [mainSwiper, setMainSwiper] = useState(null);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [buttonSwipers, setButtonSwipers] = useState([mainSwiper, thumbsSwiper]);
	const [isMobile, setMobile] = useState(window && window.screen && window.screen.availWidth && window.screen.availWidth <= 760);
	
	useEffect(() => {
		window.addEventListener('resize', () => {
			setMobile(window.screen.availWidth <= 760);
		});
	}, []);
	
	useEffect(() => {
		setButtonSwipers([mainSwiper, thumbsSwiper]);
	}, [mainSwiper, thumbsSwiper]);

	return <div className={"hotspot-gallery hotspot-full-width smaller-bottom-gap hotspot-gallery-" + type}>
		{gallery_hide_heading !== true && <h2>{type === "video" ? "Videos" : "Images"}</h2>}
		{!!(type === "video" || !isMobile || (isMobile && type === "video")) && <Swiper
			modules={[Navigation, Thumbs]} 
			thumbs={{swiper: thumbsSwiper}}
			onSwiper={setMainSwiper}
		>
			{items.map((galleryItem, index) => {
				return <SwiperSlide key={'galleryItem-' + index}>
					{type === "video" && <div className="gallery-video-view" dangerouslySetInnerHTML={{__html: cleanVideoEmbedCode(galleryItem.gallery_video_url)}} />}
					{type === "image" && <img src={galleryItem.url} alt={galleryItem.alt}/>}
				</SwiperSlide>
			})}
		</Swiper>}
		{items.length > 1 && <Swiper
			autoHeight={true}
			modules={[Navigation, Thumbs]}
			watchSlidesProgress
			onSlideChange={setThumbsSwiper}
			onSwiper={setThumbsSwiper}
			slidesPerView={isMobile ? 1 : 3}
		>
			<GalleryButton swipers={buttonSwipers} direction={"prev"} isMobile={isMobile} />
			{items.map((item, index) => {
				return <SwiperSlide key={type + 'galleryThumb-' + index}>
					<GalleryItem  item={item} type={type}/>
				</SwiperSlide>
			})}
			<GalleryButton swipers={buttonSwipers} direction={"next"} isMobile={isMobile} />
		</Swiper>}
	</div>;
	
}

export default Gallery;