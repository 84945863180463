
const transformHotspotName = (name) => name.replace(/\s+/g, '-').replace(',', '').toLowerCase();

export const cleanVideoEmbedCode = (embedCode) => embedCode.replace(/(height|width)="([0-9]+)"/g, '');

export const removePElem = (html) => {
	const tmpElem = document.createElement('div');
	tmpElem.innerHTML = html;
	if (tmpElem.firstElementChild && tmpElem.firstElementChild.tagName.toLowerCase() === 'p') {
		return tmpElem.firstElementChild.innerHTML;
	}
	return tmpElem.innerHTML;
}

export default transformHotspotName;

export const eventsClick = () => {
	const eventsLink = document.querySelector('A.custom-menu-item.events');
	if (eventsLink) {
		eventsLink.click();
		setTimeout(() => {
			const playButton = document.querySelector('button[data-id="join-dino-talkx"]');
			if (playButton) {
				playButton.click();
			}
		}, 300);
	}
}

export const moveToViewpoint = (newViewPoint, inViewpoint, setMainMenuActiveItem, setUrlScene, setCurrentViewpoint) => {
	if (newViewPoint !== inViewpoint) {
		console.log("Moving to", newViewPoint, "from", inViewpoint);
		let viewpointController = document.getElementById("viewpoint-controller");
		viewpointController.emit('reloadviewpoints', {newViewpoints: newViewPoint, currentViewpoints: inViewpoint});
		viewpointController.emit('startfadeout', newViewPoint);
		window.activeViewpoint = newViewPoint;
		setMainMenuActiveItem(newViewPoint);
		setUrlScene(newViewPoint);
		setCurrentViewpoint(newViewPoint);
	}
}

export const isRegisteredToContentEvent = (content) => {
	return !(localStorage.getItem('registration-done-' + getContentEventId(content)) === null);
};

export const getContentEventId = (content) => {
	if (content) {
		if (content.event_id) {
			return content.event_id;
		} else if (content.event_name) {
			return 'ag__' + transformHotspotName(content.event_name);
		}
	}
	return 'unknown';
};

export const getContentEventName = (content) => {
	return (content && content.event_name) || 'event';
};

export const isContentEventHotspotVisible = (content, isInvisible = false) => {
	return isInvisible ? false : isContentEventOpen(content, 0, true);
}

export const isContentEventOpen = (content, openBeforeStart = 0, checkVisibility = false) => {
	if (content && content.event_start && content.event_end) {
		const startTime = getUnixTimeFromStr(content.event_start);
		const endTime = getUnixTimeFromStr(content.event_end);
		if (!isNaN(startTime) && !isNaN(endTime) && endTime > startTime) {
			const currentTime = getCurrentUnixTime();
			if (endTime > currentTime) {
				if (checkVisibility) {
					return true;
				}
				if (currentTime >= startTime - openBeforeStart) {
					return true;
				}
			}
		}
	}
	return false;
};

export const getContentEventStartTime = (content) => {
	if (content && content.event_start && content.event_end) {
		const startTime = getUnixTimeFromStr(content.event_start);
		if (!isNaN(startTime)) {
			return startTime;
		}
	}
	return -1;
};

export const getCurrentUnixTime = () => {
	return Math.round(new Date().getTime() / 1000);
}

export const getUnixTimeFromStr = (str) => {
	return Math.round(new Date(str).getTime() / 1000);
}