const AFRAME = window.AFRAME;

AFRAME.registerComponent('hotspot', {
    multiple: true,
    schema: {
        groupIndex: { type: "number" },
        pointIndex: { type: "number" },
        pointName: { type: "string" },
        screenCoordX: { type: "number", default: 0 },
        screenCoordY: { type: "number", default: 0 },
        objectOffScreen: { type: "bool", default: false },
        inViewpoint: { type:"string", default: "" },
        linkToViewpoint: { type:"string", default: "" },
    },

    init: function () {
        // console.log(this.data);
        // console.log(this.el.getAttribute('position'));
        this.pointName = this.data.pointName;
        this.screenCoordX = this.data.screenCoordX;
        this.screenCoordY = this.data.screenCoordY;
        this.inViewpoint = this.data.inViewpoint;
        this.linkToViewpoint = this.data.linkToViewpoint;
        this.groupIndex = this.data.groupIndex;
        this.pointIndex = this.data.pointIndex;
        window.componentsInitialized += 1;
        this.hasTicked = false;

        // this.el.setAttribute("src", "#img-hotspot");
        // this.el.setAttribute("scale", "0.2 0.2 0.2");
        this.el.setAttribute("look-at", "#cam");

        const canvas = document.getElementsByClassName("a-canvas");
        if (canvas != null) {
            canvas[0].addEventListener('mousedown', () => {
                this.mouseDown = true;
            });
            
            canvas[0].addEventListener('mousemove', () => {
                this.cameraMoving = true;
            });
            
            canvas[0].addEventListener('mouseup', () => {
                this.mouseDown = false;
                this.cameraMoving = false;
            });

            canvas[0].addEventListener('touchstart', () => {
                this.mouseDown = true;
            });

            document.addEventListener('touchstart', () => {
                this.mouseDown = true;
                this.cameraMoving = true;
            });

            // In mobile, one can also look around by physically rotating the phone. Thus, we need to recalculate hotspot positions constantly
            // canvas[0].addEventListener('touchend', () => {
            //     this.mouseDown = false;
            //     this.cameraMoving = false;
            // });

            canvas[0].addEventListener('touchmove', () => {
                this.cameraMoving = true;
            });

        }

        this.el.addEventListener('click', () => {
            let currentViewpoints = this.el.parentNode.getAttribute("id");
            console.log(currentViewpoints);
            window.moveToViewpoint(this.data.linkToViewpoint, currentViewpoints);
        });
    },

    tick: function () {
        
        if (this.hasTicked && !isNaN(this.data.screenCoordX) && !this.mouseDown && !this.cameraMoving) {
            return;
        }
        if (!this.hasTicked) {
            this.hasTicked = true;
        }
        
        // Debugs
        // if (this.groupIndex == 3 && this.pointIndex == 1) {
        //     let pos = this.el.getAttribute('position');
        //     console.log(pos);
        // }

        var object = this.el.object3D;

        const renderer = this.el.sceneEl.renderer;
        const camera = this.el.sceneEl.camera;
        const canvasWidth = renderer.domElement.clientWidth;
        const canvasHeight = renderer.domElement.clientHeight;

        let pos = new window.THREE.Vector3();
        pos = pos.setFromMatrixPosition(object.matrixWorld);
        pos.project(camera);
        
        pos.x = (pos.x * canvasWidth / 2) + canvasWidth / 2;
        pos.y = - (pos.y * canvasHeight / 2) + canvasHeight / 2;
        
        this.data.screenCoordX = pos.x;
        this.data.screenCoordY = pos.y;
        this.data.objectOffScreen = pos.z >= 0 && pos.z < 1;
        pos.z = 0;

        // Debugs
        // if (this.groupIndex == 3 && this.pointIndex == 1) {
        //     console.log(this.el.getAttribute('position'), this.data.screenCoordX, this.data.screenCoordY, this.data.objectOffScreen);
        // }

        if (window.ViewHotspotRefs != null 
            && window.ViewHotspotRefs[this.data.groupIndex] != null
            && window.ViewHotspotRefs[this.data.groupIndex][this.data.pointIndex] != null) {
            window.ViewHotspotRefs[this.data.groupIndex][this.data.pointIndex]();
        }
    },
    getUpdatedPosition: function() {

        const camera = this.el.sceneEl.camera;
        var raycaster = new window.THREE.Raycaster();

        // let dummySphere = document.getElementById("dummy-sphere").object3D;
        // let pos = new window.THREE.Vector3();
        // pos = pos.setFromMatrixPosition(this.el.object3D.matrixWorld);

        // console.log(screenCoords)

        console.log(camera.el.components['look-controls'].yawObject.rotation, camera.position);
        camera.el.components['look-controls'].yawObject.rotation.set(0, 0, 0);

        
        const waitForSomeTime = async () => {
            const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
            await wait(1000);
        }
        waitForSomeTime().then(() => {
            var object = this.el.object3D;
    
            const renderer = this.el.sceneEl.renderer;
            const canvasWidth = renderer.domElement.clientWidth;
            const canvasHeight = renderer.domElement.clientHeight;
    
            let pos = new window.THREE.Vector3();
            pos = pos.setFromMatrixPosition(object.matrixWorld);
            pos.project(camera);
            
            pos.x = (pos.x * canvasWidth / 2) + canvasWidth / 2;
            pos.y = - (pos.y * canvasHeight / 2) + canvasHeight / 2;
            
            this.data.screenCoordX = pos.x;
            this.data.screenCoordY = pos.y;
            this.data.objectOffScreen = pos.z >= 0 && pos.z < 1;
            pos.z = 0;
    
            // camera.position.set(0, 1.6, 0);
            // camera.rotation.set({x: 0, y: 0, z: 0});
            const vector = new window.THREE.Vector3( this.data.screenCoordX, this.data.screenCoordY, -1 ).unproject( camera )
            console.log(vector)
            return new Promise(resolve => vector);
        })

        // raycaster.setFromCamera(screenCoords, camera);
        // var intersects = raycaster.intersectObject(dummySphere);
        // console.log(intersects)

        // pos.unprojectVector(screenCoords, camera);
        // let ray = new window.THREE.Ray(camera.position, screenCoords.subSelf(camera.position).normalize());
        // let intersects = ray.intersectObject(dummySphere);

        // return vector;
    }
});
