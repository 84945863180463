const AFRAME = window.AFRAME;

AFRAME.registerComponent('hotspotgroup', {
    multiple: true,
    schema: {
        name: { type: "string" }
    },
    init: function () {
        if (this.data.name !== "street") {
            this.el.setAttribute("scale", "0 0 0");
        }
    }
});
