import '../stylesheets/HotspotContainer.less';
import { Container, Icon, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import ViewHotspot from './ViewHotspot.js'
import ContentHotspot from './ContentHotspot.js'
import { useState, useEffect } from 'react';
import React from 'react';
import transformHotspotName, { eventsClick, moveToViewpoint } from '../utils/helpers.js'
import axios from 'axios';

const waitUntilHotspotsInitialized = async (numOfComponents) => {
    const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
    let initialized = (window.componentsInitialized === numOfComponents);
    while (!initialized) {
        await wait(100);
        initialized = (window.componentsInitialized === numOfComponents);
    }
}

const HotspotContainer = ({ hotspots, isMobile, isSceneLoaded, setMainMenuActiveItem, setFloatContent, setFloatReturn, setUrlScene, setUrlHotspot, currentViewpoint, setCurrentViewpoint }) => {
    
    const [sceneLoaded, setSceneLoaded] = useState(false)
    const [hotspotsReady, setHotspotsReady] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [reRenderNumber, setReRenderNumber] = useState(0)
    const [hotspotContents, setHotspotContents] = useState({});
    const [isCancelled, setCancelled] = useState(false);

    let hotspotEls = document.querySelectorAll('[hotspot]')

    
    if (!sceneLoaded && document.querySelector('a-scene') != null) {
        console.log("setting scene loaded")
        setSceneLoaded(true);
    }
    
    if (sceneLoaded && !hotspotsReady) {
        const numOfComponents = Array.of(hotspots)[0].reduce( (count, cur) => 
            count + (cur.viewpoint_data?.length || 0) + (cur.hotspot_data?.length || 0), 0 );
        waitUntilHotspotsInitialized(numOfComponents).then( () => {
            // console.log("viewpoints initialized")
            setHotspotsReady(true);
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.has("events") && queryParams.get("events") === "1") {
                eventsClick();
            }
        });
    }
    
    const getHotspotContent = (slug, contentFromHotspot) => {
        if (!hotspotContents || !(hotspotContents.hasOwnProperty(slug))) {
            return '';
        }
        return !!contentFromHotspot ? {...hotspotContents[slug], ...contentFromHotspot} : hotspotContents[slug];
    }
    
    useEffect(() => {
        if (!Object.keys(hotspotContents).length) {
            axios.get("/data/contents.json")
                .then((response) => {
                    if (!isCancelled) {
                        setHotspotContents(response.data);
                    }
                })
                .catch((e) => {
                    console.log("Fetching hotspot contents failed", e);
                })
            ;
        }
        
        return () => {
            setCancelled(true);
        }
    }, []);

    // console.log("re-rendered")
    useEffect(() => {
        console.log("currentViewpoint set to", currentViewpoint);
        if (currentViewpoint === "factory") {
            const introButton = document.querySelector("button[data-id='intro']");
            if (introButton) {
                introButton.click();
            }
        }
        if (currentViewpoint === "events") {
            eventsClick();
        }
    }, [currentViewpoint])
    
    useEffect(() => {
        // console.log("hotspotsReady")
        // console.log(hotspotsReady)
        setReRenderNumber(value => value + 1)
    }, [hotspotsReady])

    // Open welcome hotspot automatically when user comes first time
    useEffect(() => {
        if (hotspotsReady && currentViewpoint == 'street') {
            if (!window.sessionStorage.getItem('last-visited--street_c_1')) { // This is set when content popup is closed
                const welcomeButton = document.querySelector("button[data-id='dino-hub-intro']");
                if (welcomeButton) {
                    welcomeButton.click();
                }
            }
        }
    }, [currentViewpoint, hotspotsReady])

    const findHotspotPositions = (group, groupData, hotspots) => {
        return (groupData.map(spot => {
            let newPos = [...hotspots].find(node => {
                let nodeData = node?.components["hotspot"].data;
                if (nodeData?.groupIndex === group.groupIndex && nodeData?.pointIndex === spot.index)
                    return true;
                return false;
            }).components["hotspot"].getUpdatedPosition();
            console.log(newPos)
            return { ...spot, position: newPos };
        }));
    }
    
    const saveHotspotLocations = () => {
        // todo: update hotspot positions
        let sceneEl = document.querySelector('a-scene');
        let hotspotEls = sceneEl.querySelectorAll('[hotspot]');
        hotspots = hotspots.map(group => {
            let updatedViewpoints = findHotspotPositions(group, group.viewpoint_data, hotspotEls);
            let updatedHotspots = (group.hotspot_data != null) ? findHotspotPositions(group, group.hotspot_data, hotspotEls) : null;
            return {
                ...group, viewpoint_data: updatedViewpoints, hotspot_data: updatedHotspots
            }
        });
        console.log(hotspots)
        const blob = new Blob([JSON.stringify(hotspots)], { type: "text/plain" })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = 'hotspot_list.json'
        link.href = url
        link.click()
    }
    
    const fetchHotspotScreenPositions = (spot, groupIndex) => {
        hotspotEls = (hotspotEls.length === 0) ? document.querySelectorAll('[hotspot]') : hotspotEls;
        var spotHotspot = Array.from(hotspotEls).find(p => 
            p.components.hotspot.groupIndex === groupIndex 
            && p.components.hotspot.pointIndex === spot.index);
        var spotHotspotComponent = spotHotspot?.components.hotspot;
        return spotHotspotComponent;
    }
    
    const numOfPointsInGroup = (g) => (g.viewpoint_data?.length || 0) + (g.hotspot_data?.length || 0);

    window.ViewHotspotRefs = (window.ViewHotspotRefs) || [...Array(hotspots.length).keys()].map(g => [...Array(numOfPointsInGroup(g)).keys()].map(p => null));
    const moveToViewpointLocal = (newViewPoint, inViewpoint) => {
        moveToViewpoint(newViewPoint, inViewpoint, setMainMenuActiveItem, setUrlScene, setCurrentViewpoint);
    }
    window.moveToViewpoint = moveToViewpointLocal;     
    
    return (
        <>
            {
                (window.debug === true) ? 
                <Container id="save-container" key="save-container">
                    <Button.Content className="catch-pointer-events" onClick={saveHotspotLocations}>
                        <Icon name="save" className="catch-pointer-events" size="large"></Icon>
                    </Button.Content>
                    {/* <Button onClick={() => moveToViewpoint("complete-mill")} size='tiny' className="catch-pointer-events">complete-mill</Button> */}
                    <Button onClick={() => setIsDirty(!isDirty)} size='small' className="catch-pointer-events">re-render</Button>
                </Container> : <></>
            }
            <Container className="overlay-ui hotspot-content-container" key="overlay-ui">
                {
                    (isSceneLoaded && hotspotsReady) ?
                    hotspots.map(group => {
                        let groupNameMod = transformHotspotName(group.groupName);
                        return (
                            (groupNameMod === currentViewpoint) ?
                            group.viewpoint_data?.map( spot => (
                                <ViewHotspot 
                                    key={groupNameMod + '_v_' + spot.index.toString()}
                                    viewpointComponent={fetchHotspotScreenPositions(spot, group.groupIndex)} 
                                    name={spot.name}
                                    groupIndex={group.groupIndex}
                                    index={spot.index}
                                    inViewpoint={spot.inViewpoint}
                                    linkTo={spot.linkToViewpoint}
                                    moveToViewpoint={moveToViewpointLocal}
                                    types={spot.types}/>))
                            : null
                    )})
                    : <></>
                }
                {
                    (isSceneLoaded && hotspotsReady) ?
                    hotspots.map(group => {
                        let groupNameMod = transformHotspotName(group.groupName);
                        return (
                            (groupNameMod === currentViewpoint) ?
                            group.hotspot_data?.map( spot => {
                                const key = groupNameMod + '_c_' + spot.index.toString();
                                return <ContentHotspot 
                                    key={key}
                                    hotspotID={key}
                                    hotspotComponent={fetchHotspotScreenPositions(spot, group.groupIndex)} 
                                    name={spot.name}
                                    groupIndex={group.groupIndex}
                                    index={spot.index}
                                    inViewpoint={spot.inViewpoint}
                                    setFloatContent={setFloatContent}
                                    setFloatReturn={setFloatReturn}
                                    setUrlHotspot={setUrlHotspot}
                                    content={spot.slug ? getHotspotContent(spot.slug, spot.contentDataAndSettings) : (typeof(spot.content) === 'string' ? spot.content : '')}
                                    hotspots={hotspots}
                                    hotspotContents={hotspotContents}
                                    types={spot.types}/>})
                            : null
                    )})
                    : <></>
                }
            </Container>
        </>
    )
};

HotspotContainer.propTypes = {
    hotspots: PropTypes.array,
    isMobile: PropTypes.bool,
    isSceneLoaded: PropTypes.bool,
    setMainMenuActiveItem: PropTypes.func
}

export default HotspotContainer;