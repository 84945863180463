const GalleryButton = ({swipers, direction, isMobile}) => {
	
	return <div 
		className={"gallery-button gallery-button-" + direction} 
		onClick={(e) => {direction === "prev" ? swipers.map((swiper) => {
			if (swiper && typeof(swiper.slidePrev) === 'function') {
				swiper.slidePrev();
			}
		}) : swipers.map((swiper) => {
			if (swiper && typeof(swiper.slideNext) === 'function') {
				swiper.slideNext();
			}
		})}}
	>
		{direction === "prev" && <i className="fal fa-chevron-left" />}
		{direction === "next" && <i className="fal fa-chevron-right" />}
	</div>;
}

export default GalleryButton;